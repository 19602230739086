import { onMounted, onBeforeUnmount, ref } from "vue";
import userBtn from "./userBtn.vue";
import search from "./searchTopNav.vue";
export default {
  components: {
    userBtn,
    search
  },
  setup() {
    const showSearch = ref(true);
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition >= 90) {
        // console.log("滚动超过90px，隐藏掉search");
        showSearch.value = false;
        // console.log(showSearch.value);
        // console.log(scrollPosition);
      } else {
        // console.log("滚动小于100px， search 出现");
        showSearch.value = true;
        // console.log(showSearch.value);
        // console.log(scrollPosition);
      }
    };
    onMounted(() => {
      window.addEventListener("scroll", handleScroll);
    });
    onBeforeUnmount(() => {
      window.removeEventListener("scroll", handleScroll);
    });
    return {
      showSearch
    };
  }
};