import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "wrapper"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_affix = _resolveComponent("el-affix");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_affix, {
    offset: $props.sendOffset
  }, {
    default: _withCtx(() => [_createElementVNode("div", {
      class: "sideNav",
      style: _normalizeStyle({
        width: $props.sendWidth
      })
    }, null, 4)]),
    _: 1
  }, 8, ["offset"])]);
}