import { onMounted } from "vue";
export default {
  components: {},
  props: {},
  setup() {
    onMounted(() => {
      const logInButton = document.querySelector(".iconWrapper");
      logInButton.addEventListener("click", () => {
        console.log("点击了登录按钮");
      });
    });
    return {};
  }
};