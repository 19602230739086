import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "search"
};
const _hoisted_2 = {
  class: "dropDownMenu"
};
const _hoisted_3 = {
  class: "el-dropdown-link"
};
const _hoisted_4 = {
  class: "searchBox"
};
const _hoisted_5 = {
  class: "search-1"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_arrow_down = _resolveComponent("arrow-down");
  const _component_el_icon = _resolveComponent("el-icon");
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item");
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu");
  const _component_el_dropdown = _resolveComponent("el-dropdown");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_el_dropdown, {
    onCommand: $setup.handleCommand,
    class: "currentSearchItem",
    size: "large"
  }, {
    dropdown: _withCtx(() => [_createVNode(_component_el_dropdown_menu, null, {
      default: _withCtx(() => [_createVNode(_component_el_dropdown_item, {
        command: "找陈皮",
        class: "dropdownItem"
      }, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [_createTextVNode("找陈皮")])),
        _: 1
      }), _createVNode(_component_el_dropdown_item, {
        command: "找厂家",
        class: "dropdownItem"
      }, {
        default: _withCtx(() => _cache[3] || (_cache[3] = [_createTextVNode("找厂家")])),
        _: 1
      }), _createVNode(_component_el_dropdown_item, {
        command: "个人卖家",
        class: "dropdownItem"
      }, {
        default: _withCtx(() => _cache[4] || (_cache[4] = [_createTextVNode("个人卖家")])),
        _: 1
      }), _createVNode(_component_el_dropdown_item, {
        command: "陈皮知识",
        class: "dropdownItem"
      }, {
        default: _withCtx(() => _cache[5] || (_cache[5] = [_createTextVNode("陈皮知识")])),
        _: 1
      })]),
      _: 1
    })]),
    default: _withCtx(() => [_createElementVNode("span", _hoisted_3, [_createTextVNode(_toDisplayString($setup.pickUpItem) + " ", 1), _createVNode(_component_el_icon, {
      class: "el-icon--right",
      size: "18"
    }, {
      default: _withCtx(() => [_createVNode(_component_arrow_down)]),
      _: 1
    })])]),
    _: 1
  }, 8, ["onCommand"])]), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createElementVNode("input", {
    type: "text",
    class: _normalizeClass({
      shuru: true
    }),
    style: _normalizeStyle({
      height: $props.sendHeight
    }),
    placeholder: "搜索",
    onInput: _cache[0] || (_cache[0] = $event => $setup.refShuRu = $event.target.value),
    onKeyup: _cache[1] || (_cache[1] = (...args) => $setup.handleKeyUp && $setup.handleKeyUp(...args))
  }, null, 36), _createElementVNode("div", {
    class: "searchWrapper",
    style: _normalizeStyle({
      height: $props.sendHeight === '36px' ? '36px' : '72px'
    })
  }, [_createElementVNode("button", {
    class: _normalizeClass(['search-btn']),
    style: _normalizeStyle({
      width: $props.sendHeight === '36px' ? '88px' : '124px',
      height: $props.sendHeight === '36px' ? '' : '56px'
    })
  }, " 搜索 ", 4)], 4)])])]);
}