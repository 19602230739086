import { onMounted, ref, watch } from "vue";
export default {
  components: {},
  props: {
    sendHeight: {
      type: String,
      default: "36px"
    }
  },
  setup() {
    // const n = ref(70);
    // const clickBtn = () => {
    //   context.emit("update:xxx", (n.value += props.xxx));
    //   console.log(n.value);
    // };
    const pickUpItem = ref("找陈皮");
    const refShuRu = ref("");
    const handleKeyUp = () => {
      if (refShuRu.value.trim()) {
        console.log("搜索内容:", refShuRu.value);
        // 执行实际的搜索逻辑，例如发起 API 请求
      } else {
        console.log("请输入有效的搜索内容！");
      }
    };
    const handleCommand = command => {
      console.log(`click on item ${command}`);
      pickUpItem.value = command;
    };
    onMounted(() => {
      const searchBotton = document.querySelector(".search-btn");
      searchBotton.addEventListener("click", function () {
        console.log(refShuRu.value);
      });
      console.log(searchBotton.children);
      watch(() => [refShuRu.value], () => {
        console.log(refShuRu.value);
      });
    });
    return {
      pickUpItem,
      handleCommand,
      refShuRu,
      handleKeyUp
    };
  }
};