export default {
  created() {
    // 检测是否是移动端
    const isMobile = /Mobi|Android|iPhone|iPad|iPod|Windows Phone/i.test(navigator.userAgent);
    if (isMobile) {
      // 如果是移动端，重定向到移动端页面
      //window.location.href = "https://baidu.com"; // 替换为移动端页面的 URL
      console.log("重定向了");
    }
  },
  setup() {
    return {};
  }
};