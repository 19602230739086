import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "topNavWrapper"
};
const _hoisted_2 = {
  class: "topNav"
};
const _hoisted_3 = {
  class: "topMenu"
};
const _hoisted_4 = {
  class: "topMenuItem"
};
const _hoisted_5 = {
  class: "topMenuItem"
};
const _hoisted_6 = {
  class: "topMenuItem"
};
const _hoisted_7 = {
  class: "topMenuItem"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_userBtn = _resolveComponent("userBtn");
  const _component_search = _resolveComponent("search");
  const _component_User = _resolveComponent("User");
  const _component_el_icon = _resolveComponent("el-icon");
  const _component_router_link = _resolveComponent("router-link");
  const _component_ChatRound = _resolveComponent("ChatRound");
  const _component_Star = _resolveComponent("Star");
  const _component_Setting = _resolveComponent("Setting");
  const _component_el_affix = _resolveComponent("el-affix");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_affix, {
    offset: 0
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createVNode(_component_userBtn), _createElementVNode("div", {
      class: _normalizeClass(["search-top", {
        showSearch: $setup.showSearch
      }])
    }, [_createVNode(_component_search)], 2), _createElementVNode("div", _hoisted_3, [_createVNode(_component_router_link, {
      to: "/about",
      class: "link"
    }, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_4, [_createVNode(_component_el_icon, {
        size: "22",
        class: "icon"
      }, {
        default: _withCtx(() => [_createVNode(_component_User)]),
        _: 1
      }), _cache[0] || (_cache[0] = _createElementVNode("p", {
        style: {
          "font-size": "0.8em"
        },
        class: "menuText"
      }, "卖家中心", -1))])]),
      _: 1
    }), _createVNode(_component_router_link, {
      to: "/",
      class: "link"
    }, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_5, [_createVNode(_component_el_icon, {
        size: "22"
      }, {
        default: _withCtx(() => [_createVNode(_component_ChatRound)]),
        _: 1
      }), _cache[1] || (_cache[1] = _createElementVNode("p", {
        style: {
          "font-size": "0.8em"
        },
        class: "menuText"
      }, "消息", -1))])]),
      _: 1
    }), _createVNode(_component_router_link, {
      to: "/about",
      class: "link"
    }, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_6, [_createVNode(_component_el_icon, {
        size: "22"
      }, {
        default: _withCtx(() => [_createVNode(_component_Star)]),
        _: 1
      }), _cache[2] || (_cache[2] = _createElementVNode("p", {
        style: {
          "font-size": "0.8em"
        },
        class: "menuText"
      }, "收藏", -1))])]),
      _: 1
    }), _createVNode(_component_router_link, {
      to: "/",
      class: "link"
    }, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_7, [_createVNode(_component_el_icon, {
        size: "22"
      }, {
        default: _withCtx(() => [_createVNode(_component_Setting)]),
        _: 1
      }), _cache[3] || (_cache[3] = _createElementVNode("p", {
        style: {
          "font-size": "0.8em"
        },
        class: "menuText"
      }, "我的", -1))])]),
      _: 1
    })])])]),
    _: 1
  })]);
}