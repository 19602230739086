import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '../assets/A.png';
import _imports_1 from '../assets/B.png';
import _imports_2 from '../assets/C.png';
import _imports_3 from '../assets/D.png';
const _hoisted_1 = {
  class: "wrapper"
};
const _hoisted_2 = {
  class: "sideNav"
};
const _hoisted_3 = {
  class: "right-view"
};
const _hoisted_4 = {
  class: "home"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_sideNav = _resolveComponent("sideNav");
  const _component_topNav = _resolveComponent("topNav");
  const _component_searchTopNav = _resolveComponent("searchTopNav");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_sideNav)]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_topNav, {
    class: "top"
  }), _createElementVNode("div", _hoisted_4, [_createVNode(_component_searchTopNav, {
    class: "viewSearch",
    sendHeight: '72px'
  }), _renderSlot(_ctx.$slots, "default", {}, undefined, true), _cache[0] || (_cache[0] = _createElementVNode("img", {
    src: _imports_0,
    alt: ""
  }, null, -1)), _cache[1] || (_cache[1] = _createElementVNode("img", {
    src: _imports_1,
    alt: ""
  }, null, -1)), _cache[2] || (_cache[2] = _createElementVNode("img", {
    src: _imports_2,
    alt: ""
  }, null, -1)), _cache[3] || (_cache[3] = _createElementVNode("img", {
    src: _imports_3,
    alt: ""
  }, null, -1))])])]);
}