export default {
  props: {
    sendWidth: {
      type: String,
      default: "180px"
    },
    // height: Number,
    sendOffset: {
      type: Number,
      default: 0
    }
  }
};