import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "userLogoWrapper"
};
const _hoisted_2 = {
  class: "userLogo"
};
const _hoisted_3 = {
  class: "iconWrapper"
};
const _hoisted_4 = {
  class: "userLogo"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_User = _resolveComponent("User");
  const _component_el_icon = _resolveComponent("el-icon");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createVNode(_component_el_icon, {
    size: "22"
  }, {
    default: _withCtx(() => [_createVNode(_component_User)]),
    _: 1
  })]), _cache[0] || (_cache[0] = _createElementVNode("p", {
    class: "logInText"
  }, "登录", -1))])])]);
}